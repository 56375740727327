// src/components/NewsletterPopup.js
import React, { useEffect, useState } from "react";
import { FaCamera } from "react-icons/fa";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import popup from "../../components/media/banner/newsletter-popup.jpg";
import { listCategoryProducts } from "../../functions/ProductDetails/ProductCategory";
import LoginModal from "./loginModal";
Modal.setAppElement("#root");

const ProcuctDetislPopup = ({ isOpen, onClose }) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [randomImage, setRandomImage] = useState(null);

  const [modalList, setModalList] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const tog_list_Camera = () => {
    const userid = localStorage.getItem("RegisterUserID");
    if (!userid) {
      console.log("user found");
      setModalList(true);
      setIsSubmit(false);
    }
  };

  // useEffect(() => {
  //   const alreadySubscribed = localStorage.getItem("subscribed");
  //   if (!alreadySubscribed) {
  //     setIsOpen(true);
  //   }
  // }, []);

  // const closeModal = () => {
  //   setIsOpen(false);
  //   localStorage.setItem("subscribed", "true");
  // };

  // Reset the subscribed flag when the page is refreshed
  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     localStorage.removeItem("subscribed");
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const [categPro, setCategPro] = useState([]);

  // useEffect(() => {
  //   loadCategoryProducts();
  // }, []);

  // const loadCategoryProducts = () => {
  //   listCategoryProducts().then((res) => {
  //     console.log("RES IN CATEG PROD", res.data);
  //     setCategPro(res.data);
  //   });
  // };
  useEffect(() => {
    const loadCategoryProducts = async () => {
      try {
        const response = await listCategoryProducts();
        const categoryProducts = response.data;

        if (categoryProducts && categoryProducts.length > 0) {
          // Choose a random image from the list
          const randomIndex = Math.floor(
            Math.random() * categoryProducts.length
          );
          setRandomImage(categoryProducts[randomIndex]?.ProductImage);
          // setRandomImage("uploads/CategoryProducts/1702556515853_20 (1).jpg");

          console.log("rr", randomIndex);
          console.log("IMAGE", categoryProducts[randomIndex]?.ProductImage);
        }
      } catch (error) {
        console.error("Error loading category products:", error);
      }
    };

    loadCategoryProducts();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Subscribe to Newsletter"
    >
      <div className="popup-shadow" />
      <div className="newsletter-popup">
        <a to="#" className="newsletter-close" onClick={onClose} />
        <Container>
          <Row style={{ background: "#fff", border: "2px solid #3c2147" }}>
            <Col lg="6">
              <div className="newsletter-img">
                <img
                  src={
                    `${process.env.REACT_APP_API_URL_ZIYA}/${randomImage}` ||
                    popup
                  }
                  alt
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="newsletter-form">
                <form action="#" method="post">
                  <div className="newsletter-title">
                    <div className="title">
                      This Design
                      <br /> might look good on you
                    </div>
                  </div>
                  <div className="tryAtBtn" onClick={tog_list_Camera}>
                    <Link
                      to="#"
                      className="button button-primary animation-horizontal"
                    >
                      TRY AT ON <FaCamera />
                    </Link>
                  </div>
                </form>
              </div>
            </Col>
            <LoginModal modalList={modalList} setModalList={setModalList} />
          </Row>
        </Container>
      </div>
    </Modal>
  );
};

export default ProcuctDetislPopup;
