import { Formik } from "formik";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Input, Label } from "reactstrap";
import { FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import popup from "../../assets/media/brand/4.jpg";
import CSignUp from "./CSignUp";
import logo from "../../components/media/ziya-logo-favicon.png";
import { getUserByWP } from "../../functions/Prospect/Prsopect";
import Design from "../../components/media/banner/intro-2.jpg";

const CLogin = ({ modalList, setModalList, handleclick, TogListSignIn }) => {
  const [show, setShow] = useState(false);

  // const tog_list = () => {
  //   setModalList(!modalList);
  //   // setValues(initialState);
  // };
  //   const [modalListSignup, setModalListSignUp] = useState(false);

  //   const handleSignUpClick = () => {
  //     setShow(!show);
  //     console.log("s", modalListSignup);
  //     setModalListSignUp(!modalListSignup);
  //     console.log("sss", modalListSignup);
  //   };

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [ContactNo, setContactNo] = useState("");

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log("no errors");
    }
  }, [formErrors, isSubmit]);
  const handleChangeProspect = (e) => {
    setContactNo(e.target.value);
  };
  const [errCo, setErrCo] = useState(false);

  const validateProspect = (ContactNo) => {
    const errors = {};
    const phone = /^\d{10}$/;

    if (!ContactNo) {
      errors.ContactNo = "Contact No is required!";
      setErrCo(true);
    } else if (!phone.test(ContactNo)) {
      errors.ContactNo = "This is not a valid Phone Number";
      setErrCo(true);
    }
    if (ContactNo) {
      setErrCo(false);
    }

    return errors;
  };

  const validClassCNo =
    errCo && isSubmit ? "form-control is-invalid" : "form-control";

  const handleSubmitProspect = (e) => {
    e.preventDefault();

    // set_Id(_id);
    let erros = validateProspect(ContactNo);
    setFormErrors(erros);
    setIsSubmit(true);

    if (Object.keys(erros).length == 0) {
      getUserByWP(ContactNo)
        .then((res) => {
          console.log("log user res", res.data);
          if (res.data.isOk) {
            console.log(res);
            const userLog = res.data;
            console.log("dd", userLog.data);
            localStorage.setItem("RegisterUserID", userLog.data);
            setContactNo("");
            // navigate("/plan-pricing");
            setModalList(!modalList);
          } else {
            const user = res.data;
            if (user.field === 1) {
              setErrCo(true);
              setFormErrors({ ContactNo: user.message });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      {/* <Link onClick={() => tog_list()} className="menu-link">
        <span className="menu-item-text">Login</span>
      </Link> */}

      <Modal
        centered
        show={modalList}
        // onHide={handleClose}
        // toggle={() => {
        //   tog_list();
        // }}
        toggle={TogListSignIn}
      >
        <div className="newsletter-popup">
          <a
            to="#"
            className="newsletter-close"
            // onClick={() => {
            //   tog_list();
            // }}
            onClick={TogListSignIn}
          />
          <div className="newsletter-container">
            <div className="newsletter-img">
              <img src={Design} alt />
            </div>
            <div className="newsletter-form">
              <div className="login">
                <div className="form">
                  <form>
                    <div style={{ marginBottom: "40px" }}>
                      <h4>Login</h4>
                    </div>

                    <Col lg={12}>
                      <div className="form-floating mb-3">
                        <Input
                          style={{ borderColor: "black" }}
                          type="number"
                          className={validClassCNo}
                          id="companynamevalidation"
                          placeholder="Enter your Contact Number"
                          required
                          name="ContactNo"
                          value={ContactNo}
                          onChange={handleChangeProspect}
                        />
                        <Label htmlFor="contactnumber" className="form-label">
                          Contact Number
                        </Label>

                        {isSubmit && (
                          <p className="text-danger">{formErrors.ContactNo}</p>
                        )}
                      </div>
                    </Col>

                    <p>
                      Don’t have an account ?{" "}
                      <Link to="" onClick={() => handleclick("2")}>
                        Sign Up
                      </Link>
                      {/* <span>
                        <CSignUp onClick={handleClose} />
                      </span>{" "} */}
                    </p>
                    <button
                      type="submit"
                      className="button button-primary animation-horizontal"
                      onClick={handleSubmitProspect}
                    >
                      Sign In
                    </button>
                  </form>
                </div>
              </div>
              {/* ContactNo */}
            </div>
          </div>
        </div>
      </Modal>
      {/* <CSignUp
        modalListSignup={modalListSignup}
        setModalListSignUp={set}
        // onClick={handleClose}
      /> */}
    </>
  );
};

export default CLogin;
