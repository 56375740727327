// STAKE HOLDER DETAILS FUNCTIONS/////////
import axios from "axios";

export const listProspect = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/prospect-list`
  );
};

export const AddToWhislist = async ({ userid, productid }) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/add-whislist-Items`,
    { userid, productid }
  );
};

export const RemoveFromWhislist = async ({ userid, productid }) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/remove-whislist-Items`,
    { userid, productid }
  );
};

export const GetWhishlistUser = async (userid) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/get-whislist-Items-user/${userid}`
  );
};

export const createProspect = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/prospect-create`,
    values
  );
};

export const getProspect = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/get-prospect/${_id}`
  );
};

// export const getUserByWP = async (no) => {
//   return await axios.get(
//     `${process.env.REACT_APP_API_URL_MARWIZ}/api/auth/prospect-login/${no}`
//   );
// };

export const getUserByWP = async (ContactNo) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/prospect-login/${ContactNo}`
  );
};
export const removeProspect = async (_id) => {
  return await axios.delete(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/prospect-remove/${_id}`
  );
};

export const updateProspect = async (user, prospect) => {
  console.log("AXIOS LOG DATA", prospect);
  return await axios.put(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/prospect-update/${user}`,
    prospect
  );
};
