import React, { useState, useEffect } from "react";
import CLogin from "./CLogin";
import CSignup from "./CSignUp";
const SignUpIndex = ({ modalList, setModalList }) => {
  const [activeTab, setActiveTab] = useState("loginPage");

  const [modalListSignup, setModalListSignUp] = useState(false);
  // const [modalList, setModalList] = useState(true);

  useEffect(() => {
    console.log("modalList", modalList);
    console.log("modalListSignup", modalListSignup);
  }, []); // Add closing parenthesis ')' here

  const TogListSignIn = () => {
    setModalList(!modalList);
  };

  const TogListRegister = () => {
    setModalListSignUp(!modalListSignup);
  };

  const handleclick = (tabname) => {
    setActiveTab(tabname);
    if (tabname === "loginPage") {
      console.log("insideee");
      setModalList(!modalList);
      setModalListSignUp(true);
    } else {
      console.log("else");
      setModalList(false);
      setModalListSignUp(!modalListSignup);
    } // Add missing semicolon ';' here
  };

  return (
    <div>
      {(() => {
        switch (activeTab) {
          case "loginPage":
            return (
              <CLogin
                modalList={modalList}
                setModalList={setModalList}
                handleclick={handleclick}
                TogListSignIn={TogListSignIn}
              />
            );
          case "2":
            return (
              <CSignup
                modalListSignup={modalListSignup}
                setModalListSignUp={setModalListSignUp}
                handleclick={handleclick}
                TogListRegister={TogListRegister}
              />
            );

          default:
            return <CLogin handleclick={handleclick} />;
        }
      })()}
    </div>
  );
};

export default SignUpIndex;
